.home{
  padding: 30px 30px 20px;
  font-family: "DM Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  max-width: 220px;
  box-sizing: content-box;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  line-height: 31px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  min-height: 40px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  background-color: #ffffff;
  border-style: solid;
  border-color: #d7d7d7;
  border-width: 1px;
  border-radius: 5px;
  transition: all 0.3s;
  text-overflow: ellipsis;
  margin-bottom: 30px;
}

.button {
  color: #ffffff;
  background-color: #3D53F6;
  font-weight: 400;
  font-size: 16px;
  min-height: 40px;
  border: none;
  border-radius: 75px;
  width: 50%;
  white-space: normal;
  padding: 9px 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}
