.mainContainer {
    background: white;
    border: 1px solid #D7D7D7;
    border-radius: 20px;
    width: 600px;
    min-height: 310px;
    margin: 30px auto;
    padding-bottom: 40px;
}
.footer {
    text-align: center;
    font-size: 18px;
}

@media screen and (max-width: 760px) {
    .mainContainer {
        width: 350px
    }
}
