body {
  margin: 0px;
  height: 100vh;
  font-family: sans-serif;
  background-color: #FFF;
}

:root {
  --passage-body-text-color: #000;
}

passage-auth::part(button) {
  --passage-primary-color: #3D53F6;
}
