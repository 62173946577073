.notFound {
    font-size: 24px;
    line-height: 31px;
    font-weight: 700;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
}
