.dashboard{
    padding: 15px 15px 15px;
}

.header {
    display: flex;
    margin-bottom: 25px;
    cursor: pointer;
}

.logout {
    margin-left: auto;
    font-size: 14px;
}
.message {
    overflow-wrap: break-word;
}
.link {
    color: black;
    text-decoration-color: black;
}

passage-profile{
    --passage-container-padding: 0px;
}
